import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/page/seo"
import Layout from "../components/page/layout"
import { MDXRenderer } from "gatsby-plugin-mdx"

const BlogIndex = props => {
  const { data } = props
  const allPosts = data.allFile.edges

  const posts = allPosts

  return (
    <Layout>
      <SEO title="Today I Learned..." />
      <h1 className="articleHeader">Today I learned...</h1>
      <p className="articleHeader">
        {" "}
        Misc. learnings. This page is currently under construction. 🚧
      </p>
      {posts.map(({ node }) => {
        const { body, frontmatter } = node.childMdx

        const { title, date } = frontmatter

        return (
          <article key={title}>
            <header>
              <h2 className="article-headline">{title}</h2>
              <p className="articlesDate">{date}</p>
            </header>
            <MDXRenderer>{body}</MDXRenderer>
            <hr className="articlesDivider" />
          </article>
        )
      })}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    allFile(
      sort: { order: DESC, fields: childMdx___frontmatter___date }
      filter: { sourceInstanceName: { eq: "til" } }
    ) {
      edges {
        node {
          childMdx {
            frontmatter {
              title
              date(formatString: "MMMM DD, YYYY")
            }
            body
          }
        }
      }
    }
  }
`
