import React from "react"
import { Link } from "gatsby"

import { Button } from "theme-ui"
const kebabCase = require(`lodash.kebabcase`)

function Tags({ tags, isSlanted = false }) {
  if (!tags) return []
  return tags.map(tag => (
    <Button className={isSlanted ? "tagButton" : "squareTagButton"} key={tag}>
      {" "}
      <Link to={`/tags/${kebabCase(tag)}/`}>
        <span className={isSlanted ? "tagContent" : "squareTagContent"}>
          {" "}
          {tag}
        </span>
      </Link>
    </Button>
  ))
}

export default Tags
