import React from "react"
import { graphql } from "gatsby"
import SEO from "./page/seo"
import { Link } from "gatsby"
import { Box } from "theme-ui"
import Headshot from "./headshot"
import WebMentions from "./WebMentions"
import Tags from "./tagsComponent"

import Layout from "./page/layout"

import { TwitterFollowButton } from "react-twitter-embed"
import { formatReadingTime } from "../utils/helpers"
import Signup from "../components/signup"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import LoadingSpinner from "../components/LoadingSpinner"
import TableOfContents from "../components/TableOfContents"
import Callout from "../components/Callout"

import { preToCodeBlock } from "mdx-utils"
import Code from "./code"

const components = {
  LoadingSpinner,
  Signup,
  TableOfContents,
  Callout,
  pre: preProps => {
    const props = preToCodeBlock(preProps)
    if (props) {
      return <Code {...props} />
    } else {
      return <pre {...preProps} />
    }
  },
}
class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx
    if (!post) return null

    const { author, siteUrl } = this.props.data.site.siteMetadata
    const { timeToRead } = post
    const { allWebMentionEntry } = this.props.data
    const { prev, next, slug } = this.props.pageContext

    const { tags } = post.frontmatter

    const url = `${siteUrl}${slug}/`

    return (
      <Layout location={this.props.location}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description}
          slug={slug}
          tags={tags ? tags.join(",") : ""}
        />
        <article className="articleBody h-entry">
          <header className="articleHeader">
            <p>
              {" "}
              <span className="readingTime">
                {" "}
                {formatReadingTime(timeToRead)}
              </span>
            </p>

            <h1
              style={{
                marginBottom: `20px`,
                margin: `0 auto`,
              }}
              className="p-name"
            >
              {post.frontmatter.title}
            </h1>

            <div
              id="author-image"
              style={{
                margin: `0 auto`,
                display: `flex`,
                maxWidth: `45%`,
                justifyContent: `space-around`,
                flexWrap: `wrap`,
                maxHeight: `100px`,
              }}
            >
              <div style={{ minWidth: `75px`, maxWidth: `75px` }}>
                {" "}
                <Headshot />
              </div>
              <p>
                <span style={{ fontSize: `20px` }}>
                  <a href="#author-bio">{author}</a>
                  <TwitterFollowButton screenName={"indigitalcolor"} />{" "}
                </span>
              </p>
              <p>
                <time
                  className="hidden dt-published"
                  itemProp="datepublished"
                  dateTime={post.frontmatter.date}
                >
                  {new Date(post.frontmatter.date)
                    .toISOString()
                    .replace("Z", "") + "+01:00"}
                </time>{" "}
              </p>
            </div>
          </header>
          <div className="articleContent">
            <MDXProvider components={components}>
              <MDXRenderer tableOfContents={post.tableOfContents}>
                {post.body}
              </MDXRenderer>
            </MDXProvider>{" "}
          </div>
          <p className="published-date">
            This article was published on {post.frontmatter.date}.
          </p>
          <Tags tags={tags} />
          <hr className="indexDivider" />
          <Signup post={post.frontmatter.title} />
          <WebMentions {...allWebMentionEntry} />
          <hr className="indexDivider" />
          <nav>
            <Box
              sx={{
                display: "grid",
                gridGap: 4,
                gridTemplateColumns: "repeat(auto-fit, minmax(128px, 1fr))",
              }}
            >
              <span p={3} bg="muted">
                {prev ? (
                  <>
                    <h3>Newer Post </h3>
                    <Link
                      to={prev.fields.slug}
                      rel="prev"
                      className="articleNavigation"
                    >
                      ← {prev.frontmatter.title}
                    </Link>
                  </>
                ) : (
                  <>
                    {" "}
                    <h3>That's All! </h3>
                    <p className="articleNavigation">
                      Thanks for reading "
                      <a href={url} className="u-url p-name">
                        {post.frontmatter.title}
                      </a>
                      "
                    </p>
                  </>
                )}
              </span>

              <span p={3} bg="muted">
                <>
                  {" "}
                  <h3>Older Post </h3>
                  {next && (
                    <Link
                      to={next.fields.slug}
                      rel="next"
                      className="articleNavigation"
                    >
                      {next.frontmatter.title} →
                    </Link>
                  )}
                </>
              </span>
            </Box>
            <br />
          </nav>
          <br />
        </article>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $permalink: String!) {
    site {
      siteMetadata {
        author
        siteUrl
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      timeToRead
      body
      tableOfContents
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
      }
    }
    allWebMentionEntry(filter: { wmTarget: { eq: $permalink } }) {
      edges {
        node {
          wmTarget
          wmSource
          wmProperty
          wmId
          type
          url
          likeOf
          author {
            url
            type
            photo
            name
          }
          published(formatString: "MMMM DD, YYYY")
          content {
            text
          }
        }
      }
    }
  }
`
