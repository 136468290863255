import React from "react"

import GatsbyIcon from "./icons/gatsby.svg"
import GitHubIcon from "./icons/github.svg"
import ReactIcon from "./icons/react.svg"
import JavascriptIcon from "./icons/javascript.svg"
import CSSIcon from "./icons/css3.svg"
import PythonIcon from "./icons/python.svg"
import PenIcon from "./icons/pen.svg"
import VideoIcon from "./icons/video.svg"
import VSCodeIcon from "./icons/visualstudiocode.svg"
import MicrophoneIcon from "./icons/microphone.svg"
import NextJSIcon from "./icons/next-dot-js.svg"
import TerminalIcon from "./icons/terminal-solid.svg"
import PaletteIcon from "./icons/palette-solid.svg"
import LinkIcon from "./icons/link.svg"
import NetlifyIcon from "./icons/netlify.svg"

export default function ArticleIcon({ tags }) {
  const icons = {
    svg: PaletteIcon,
    design: PaletteIcon,
    terminal: TerminalIcon,
    software: TerminalIcon,
    podcast: MicrophoneIcon,
    video: VideoIcon,
    css: CSSIcon,
    vscode: VSCodeIcon,
    git: GitHubIcon,
    github: GitHubIcon,
    gatsby: GatsbyIcon,
    react: ReactIcon,
    reflection: PenIcon,
    javascript: JavascriptIcon,
    python: PythonIcon,
    nextjs: NextJSIcon,
    netlify: NetlifyIcon,
    externallink: LinkIcon,
  }

  const articleTagWithIcon = (tags || []).find(
    (tag) => icons[tag.toLowerCase()]
  )

  const articleIcon = articleTagWithIcon
    ? icons[articleTagWithIcon.toLowerCase()]
    : TerminalIcon

  return (
    <span className="article-icon">
      <img
        src={articleIcon}
        alt={articleTagWithIcon ? `${articleTagWithIcon} icon` : `code terminal icon`}
      />
    </span>
  )
}
