import { Link } from "gatsby"

import React from "react"

import { useMediaQuery } from "react-responsive"
import Logo from "./logo"
import theme from "./theme"
import avatar from "../../images/monica-cropped-bg.png"
import { ThemeProvider } from "theme-ui"
import { WRITING, SPEAKING, NEWSLETTER, CONTACT } from "../../utils/routes"
import { Box, Flex } from "theme-ui"

const Nav = () => {
  const isLargeUpScreen = useMediaQuery({ query: "(min-width: 740px)" })

  return (
    <ThemeProvider theme={theme}>
      <Flex
        px={2}
        color="white"
        alignItems="center"
        variant="nav"
        role="navigation"
      >
        <Link to="/" className="navLink">
          <img
            src={avatar}
            className="nav-avatar"
            alt="animated headshot of Monica"
          />
          &nbsp; &nbsp;
          {isLargeUpScreen && <Logo variant="logo" padding="10em" />}
        </Link>
        <Box mx="auto" />
        <Link to={WRITING} className="navLink">
          Content
        </Link>
        <Link to={SPEAKING} className="navLink">
          Speaking
        </Link>
        <a
          className="navLink"
          href={NEWSLETTER}
          target="_blank"
          rel="noopener noreferrer"
        >
          Newsletter
        </a>
        <Link to={CONTACT} className="navLink">
          Contact
        </Link>
      </Flex>
    </ThemeProvider>
  )
}
Nav.propTypes = {}

Nav.defaultProps = {}

export default Nav
