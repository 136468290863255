import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import { Button } from "theme-ui"
import SEO from "../components/page/seo"

import Layout from "../components/page/layout"
import ArticleIcon from "../components/ArticleIcon"
import UndoIcon from "../components/icons/undo.svg"

const BlogIndex = props => {
  const emptyQuery = ""
  const emptySearchState = { filteredData: [], query: emptyQuery }
  const [state, setState] = useState(emptySearchState)

  const clearSearch = event => {
    setState(emptySearchState)
  }

  const handleInputChange = event => {
    /* note: value = typed query, innerText = clicked button text */
    const query = event.target.value || event.target.innerText
    const { data } = props
    const posts = data.allMdx.edges || []

    const filteredData = posts.filter(post => {
      const { description = "", title, tags } = post.node.frontmatter
      return (
        description?.toLowerCase().includes(query.toLowerCase()) ||
        title?.toLowerCase().includes(query.toLowerCase()) ||
        (tags &&
          tags
            .join("")
            .toLowerCase()
            .includes(query.toLowerCase()))
      )
    })

    setState({
      query,
      filteredData,
    })
  }

  const { filteredData, query } = state
  const { data } = props
  const allPosts = data.allMdx.edges
  const hasSearchResults = filteredData && query !== emptyQuery
  const posts = hasSearchResults ? filteredData : allPosts

  const tags = ["JavaScript", "GitHub", "React", "Gatsby"]

  return (
    <Layout>
      <SEO title="Content" />
      <h1 className="articleHeader">Content</h1>
      {tags.map(tag => (
        <Button
          onClick={handleInputChange}
          value={tag}
          className={"squareTagButton"}
          key={tag}
        >
          <span className={"squareTagContent"}>{tag}</span>
        </Button>
      ))}{" "}
      {state.query !== emptyQuery && (
        <Button onClick={clearSearch} className="resetSearch">
          <img src={UndoIcon} alt="" /> clear
        </Button>
      )}
      <div className="searchBox">
        <input
          type="text"
          aria-label="Search"
          placeholder="Type to filter posts..."
          onChange={handleInputChange}
          className="searchInput"
        />{" "}
        {<div className="postCount">{posts.length} </div>}
      </div>
      {posts.length === 0 && (
        <p className="noSearchResults">
          There were no results found for "{query}". Try searching for something
          else like "JavaScript".
        </p>
      )}
      <div className="article-blurbs">
        {posts.map(({ node }, index) => {
          const { fields, frontmatter } = node
          const { slug } = fields
          const { title, tags, date, external_link, publication } = frontmatter

          return (
            <div className="article-blurb index" key={`${index}-${slug}`}>
              <time>{date}</time>
              <span className="article-title-icon-wrap">
                <ArticleIcon tags={tags} />

                <p>
                  {external_link ? (
                    <a
                      href={external_link}
                      className="article-link"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {title} &bull;{" "}
                      <span className="external-publication-name">
                        {publication}
                      </span>
                      <span className="screen-reader-only">
                        (opens in a new tab)
                      </span>
                    </a>
                  ) : slug.startsWith("/") ? (
                    <Link to={slug} className="article-link">
                      {title}
                    </Link>
                  ) : (
                    <a href={`${slug}?af=9b5hrz`} className="article-link">
                      {title}
                    </a>
                  )}
                </p>
              </span>
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    allMdx(
      sort: { order: DESC, fields: frontmatter___date }
      filter: { fileAbsolutePath: { regex: "g//blog|external//" } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 200)
          id
          frontmatter {
            title
            category
            description
            date(formatString: "MMMM DD, YYYY")
            slug
            tags
            external_link
            publication
          }
          timeToRead
          fields {
            slug
          }
        }
      }
    }
  }
`
