export function formatReadingTime(minutes) {
  /* added 1.7x multiplier to account for the fact that
   reading times are different in gatsby-transformer-remark 
   than gatsby-plugin-mdx -- for now normalizing ~ equal 
   to times generated by gatsby-trasnformer-remark */

  let normalizedMinutes = Math.round(minutes * 1.7)
  let buckets = Math.round(normalizedMinutes / 5)

  return `${new Array(buckets || 1)
    .fill("🍿")
    .join("")} ${normalizedMinutes} min. read`
}



