import React from "react"

import { Link } from "gatsby"
import Layout from "../components/page/layout"
import SEO from "../components/page/seo"

import brokenImage from "../images/broken-image.gif"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>404: NOT FOUND</h1>
    <p>Somehow you landed on a page that doesn&#39;t exist... the sadness.</p>

    <p>
      What you're looking for may be on the <Link to="/">home page</Link>.
    </p>

    <img src={brokenImage} alt="animated illustration of broken img" />
  </Layout>
)

export default NotFoundPage
