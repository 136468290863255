const theme = {
  breakpoints: ["40em", "52em", "64em"],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  colors: {
    blue: "#07c",
    lightgray: "#f6f6ff",
    eggplant: "#2a2135",
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    body: "system-ui, sans-serif",
    heading: "inherit",
    monospace: "Menlo, monospace",
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  shadows: {
    small: "0 0 4px rgba(0, 0, 0, .125)",
    large: "0 0 24px rgba(0, 0, 0, .125)",
    card: "0 0 4px rgba(0, 0, 0, 0.125)",
  },
  variants: {
    card: {
      p: 2,
      bg: "lightgray",
      boxShadow: "card",
    },

    logo: {
      padding: "10em",
    },
    nav: {
      borderBottom: "5px solid #b4aaff",
      bg: "eggplant",
      marginBottom: "2em",
    },
    navLink: {
      color: "white",
      textDecoration: "none",
      padding: ".5em",
    },
    title: {
      color: "eggplant",
    },
  },
  text: { padding: 3, color: "eggplant" },
  buttons: {
    primary: {
      color: "white",
      bg: "eggplant",
    },
  },
}

export default theme
