import React from "react"
import PropTypes from "prop-types"

import { Link, graphql } from "gatsby"
import { TAGS } from "../utils/routes"
import Layout from "./page/layout"
import TagsComponent from "./tagsComponent"

class Tags extends React.Component {
  render() {
    const { pageContext, data } = this.props
    const posts = data.allMdx.edges

    const { tag } = pageContext
    const { totalCount } = data.allMdx
    const tagHeader = `${totalCount} post${
      totalCount === 1 ? "" : "s"
    } tagged with "${tag}"`

    return (
      <Layout location={this.props.location}>
        <h1 className="tagHeader">{tagHeader}</h1>

        {posts.map(({ node }) => {
          const { tags, title = node.fields.slug } = node.frontmatter

          return (
            <article key={node.fields.slug}>
              <header>
                <h3>
                  <Link
                    style={{ boxShadow: `none` }}
                    to={node.fields.slug}
                    className="article-headline"
                  >
                    {title}
                  </Link>
                </h3>
                <p className="articlesDate">{node.frontmatter.date}</p>
              </header>
              <section>
                <p
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description,
                  }}
                />
              </section>

              <TagsComponent tags={tags.filter(topic => topic !== tag)} />
              <hr className="articlesDivider" />
            </article>
          )
        })}
        <Link to={TAGS} className="linkTo">
          view all tags
        </Link>
      </Layout>
    )
  }
}

export default Tags
Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export const pageQuery = graphql`
  query($tag: String) {
    allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title
            category
            description
            date(formatString: "MMMM DD, YYYY")
            slug
            tags
          }
        }
      }
    }
  }
`
