import React from "react"
import { Box } from "theme-ui"

import VideoIcon from "./icons/video-lavendar.svg"
import LinkIcon from "./icons/link.svg"

const PreviousSpeaking = ({ previousEngagements, styles }) => (
  <>
    <h2>Previous Engagements</h2>
    <Box sx={styles}>
      {previousEngagements &&
        previousEngagements.map(engagement => {
          const { name, link, video, title, date, city } = engagement

          return (
            <>
              {/*  {(index == 0) && <><h2>{date.slice(-4)}</h2><br/></>}
          // {(index > 0 && date?.slice(-4) !== engagements[index - 1]?.date?.slice(-4)) && (<><h2>{date.slice(-4)}</h2><br/></>)}*/}
              <p key={name + title.length}>
                {name} {""}
                {link && (
                  <>
                    <a
                      href={link}
                      aria-label={`linkFor-${name}`}
                      className="speakingIcon"
                    >
                      <img
                        src={LinkIcon}
                        alt="Link icon"
                        className="speakingIcon"
                      />
                    </a>
                    &nbsp;
                  </>
                )}
                {video && (
                  <a
                    href={video}
                    aria-label={`videoLinkFor-${name}`}
                    className="speakingIcon"
                  >
                    <img
                      src={VideoIcon}
                      alt="Video icon"
                      className="speakingIcon"
                    />
                  </a>
                )}
                <br /> <strong>{title}</strong>
                <br /> {date}, {city}
              </p>
            </>
          )
        })}
    </Box>
  </>
)

export default PreviousSpeaking
