import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import ArticleList from "./ArticleList"

function FeaturedWriting() {
  const { allMdx } = useStaticQuery(
    graphql`
      query {
        allMdx(
          sort: { fields: frontmatter___date, order: DESC }
          filter: { frontmatter: { featured: { eq: true } } }
        ) {
          edges {
            node {
              frontmatter {
                title
                tags
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `
  )

  const articles = []

  const { edges } = allMdx
  edges.forEach(({ node }) => {
    articles.push({
      title: node.frontmatter.title,
      slug: node.fields.slug,
      tags: node.frontmatter.tags,
    })
  })

  return <ArticleList articles={articles} featured />
}

export default FeaturedWriting
