import React, { useEffect, useState } from "react"
import qs from "qs"
import SEO from "../components/page/seo"
import Layout from "../components/page/layout"
import theme from "../components/page/theme"
import { ThemeProvider } from "theme-ui"
import Spinner from "../components/LoadingSpinner"

function SchedulePage({ location }) {
  /* expected url format: /schedule?name=monica */
  const name = qs.parse(location.search.replace("?", "")).name || ""
  const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const head = document.querySelector("head")
    const script = document.createElement("script")
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    )
    head.appendChild(script)
    setLoading(false)
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Schedule" />
        <h1>
          Let's Chat{" "}
          <span role="img" aria-label="coffee-emoji">
            ☕️
          </span>
        </h1>
        <p>
          {capitalizedName && `${capitalizedName},`} I'm looking forward to
          connecting soon! Please follow the instructions to add an event to my
          calendar.
        </p>
        {loading && (
          <div
            style={{
              minWidth: "320px",
              height: "580px",
              paddingTop: "3em",
              textAlign: "center",
            }}
          >
            <Spinner />
            Calendar is loading...
          </div>
        )}
        <div
          className="calendly-inline-widget"
          data-url="https://calendly.com/monicapowell?hide_landing_page_details=1"
          style={{ minWidth: "320px", height: "580px" }}
        />
      </Layout>
    </ThemeProvider>
  )
}

export default SchedulePage
