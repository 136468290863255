import React from "react"
import Highlight, { defaultProps } from "prism-react-renderer"
import theme from "prism-react-renderer/themes/palenight"
import "./code.scss"

const RE = /{([\d,-]+)}/

const copyToClipboard = str => {
  if (navigator.clipboard) {
    // Most modern browsers support the Navigator API
    navigator.clipboard.writeText(str).then(
      function () {
        console.log("Copying code snippet to clipboard was successful!")
      },
      function (err) {
        console.error("Could not copy code snippet: ", err)
      }
    )
  } else if (window.clipboardData) {
    // Internet Explorer
    window.clipboardData.setData("Text", str)
  }
}

const calculateLinesToHighlight = meta => {
  if (!RE.test(meta)) {
    return () => false
  }
  const lineNumbers = RE.exec(meta)[1]
    .split(`,`)
    .map(v => v.split(`-`).map(x => parseInt(x, 10)))
  return index => {
    const lineNumber = index + 1
    const inRange = lineNumbers.some(([start, end]) =>
      end ? lineNumber >= start && lineNumber <= end : lineNumber === start
    )
    return inRange
  }
}

const Code = ({ codeString, language, metastring, ...props }) => {
  const shouldHighlightLine = calculateLinesToHighlight(metastring)
  const [isCopiedToClipboard, setIsCopiedToClipboard] = React.useState(false)
  return (
    <Highlight
      {...defaultProps}
      code={codeString}
      language={language}
      theme={theme}
      {...props}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <div className="gatsby-highlight" data-language={language}>
          <div className="copyBtnContainer"><button className="copy" onClick={() => {
            copyToClipboard(codeString)
            setIsCopiedToClipboard(true)
            setTimeout(() => setIsCopiedToClipboard(false), 1500)
          }}>{isCopiedToClipboard ? "Copied!" : "Copy"}</button></div>
          < pre className={className} style={style}>

            {tokens.map((line, i) => {
              const lineProps = getLineProps({ line, key: i })

              if (shouldHighlightLine(i)) {
                lineProps.className = `${lineProps.className} highlight-line`
              }

              return (
                <div {...lineProps}>
                  <span className="line-number-style">{i + 1}</span>
                  {line.map((token, key) => (
                    <span {...getTokenProps({ token, key })} />
                  ))}
                </div>
              )
            })}
          </pre>
        </div>
      )}
    </Highlight>
  )
}

export default Code
