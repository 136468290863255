import React from "react"
import logo from "../../images/monicapowelllogo2.svg"

const Logo = () => {
  return (
    <img
      src={logo}
      width={200}
      alt="monica-powell-text-logo"
      className="logo"
    />
  )
}

export default Logo
