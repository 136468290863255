import React from "react"

import Layout from "../components/page/layout"
import SEO from "../components/page/seo"
import Cards from "../components/cardComponent"

import megha from "../images/megha-header-screenshot-cropped-16-9-ratio.png"
import instantReservations from "../images/instant-reservations.png"
import Affirmations from "../images/affirmations.png"
import WebsiteGallery from "../images/website-gallery.png"
import HTTRIRI from "../images/httriri-screenshot.png"
import LoremIpsum from "../images/lorem-ipsum-generator-screenshot.png"

import { Button } from "theme-ui"

const projects = [
  {
    img: instantReservations,
    text:
      "One of the first organizer tools I contributed to at Meetup as a Full Stack Engineer was the Meetups at WeWork instant reservation system which enabled eligible organizers to instantly reserve venue space at WeWork locations globally on the Meetup website.",
    website: "https://www.meetup.com",
    technologies:
      "React, CSS, Redux, NodeJS, AWS Lambdas and other Serverless technologies.",
  },
  {
    img: HTTRIRI,
    text:
      "I created HTTRiRi for developers to explore common HTTP status codes through a collection of Rihanna GIFs. Each status code reference includes an link to a detailed description from httpstatuses.com.",
    website: "https://www.httriri.com/",
    github: "https://github.com/M0nica/httriri",
    technologies: "NextJS, a React Framework ",
  },
  {
    img: WebsiteGallery,
    text:
      "I wanted to make a more visually-engaging and functional version of my running list of websites that inspire me which is why I ended up creating this website inspiration gallery.",
    website: "https://website-gallery.now.sh/",
    github: "https://github.com/M0nica/website-gallery",
    technologies: "NextJS, a React Framework and GraphQL",
  },

  {
    img: Affirmations,

    text:
      "Who doesn't love a good quote? I created a random affirmation generator  where visitors receive randomly generated inspiration from open-source collection of positive affirmations.",

    website: "https://www.affirmations.madewithtech.com/",
    github: "https://github.com/M0nica/affirmation_generator",
    technologies: "React",
  },
  {
    img: megha,
    text:
      "I was responsible for the full-development cycle from ideation to QA when launching this philanthropist's personal branding website.",
    website:
      "https://web.archive.org/web/20150826021134/http://www.meghasdesai.com/",
    technologies: "HTML/CSS, JavaScript and CMS.",
  },

  {
    img: LoremIpsum,
    text:
      "Grey's Anatomy Lorem Ipsum generator for creating placeholder text that is Grey's Anatomy theme. ",
    website: "https://greysanatomyloremipsum.netlify.com/",
    github: "https://github.com/M0nica/greys-anatomy-lorem-ipsum-generator",
    technologies: "React and Netlify Serverless NodeJS functions.",
  },
]

const ProjectsPage = () => {
  return (
    <Layout>
      <SEO title="Projects" />
      <h1>Projects</h1>
      <p>
        I'm a product engineer who enjoys contributing to projects that elevate
        people whether that's increasing access to e-books with{" "}
        <a href="https://github.com/NYPL-Simplified">NYPL-Simplified</a>,
        creating tools for <a href="https://www.meetup.com">Meetup</a>'s{" "}
        community organizers or sending curated content to{" "}
        <a href="https://www.jopwell.com">Jopwell</a>'s diverse job-seekers at
        scale.{" "}
      </p>
      <p>
        Some technologies I enjoy working with are ReactJS,{" "}
        <a href="https://jamstack.org/">JAMStack</a> (JavaScript, APIs +
        Markup), GraphQL and more. My interest in the React ecosystem led me to
        launch{" "}
        <a
          href="https://www.meetup.com/react-ladies/"
          rel="noopener noreferrer"
        >
          React Ladies
        </a>
        , a community for women and non-binary ReactJS developers.
      </p>
      <p>
        If you'd like to learn more about my day-to-day engineering work, aside
        from the projects below, then{" "}
        <a href="/resume.pdf" className="linkTo">
          view my resume
        </a>{" "}
        or <a href="https://github.com/m0nica">GitHub</a>.
      </p>
      <Cards items={projects} />
      <br />{" "}
      <a href="/resume.pdf" className="resumeLink">
        <Button>view resum&eacute; </Button>
      </a>{" "}
    </Layout>
  )
}

export default ProjectsPage
