import React from "react"
import { Link } from "gatsby"
import { CONTACT } from "../utils/routes"
import Layout from "../components/page/layout"
import SEO from "../components/page/seo"
import { Flex, Box } from "theme-ui"
import { useMediaQuery } from "react-responsive"
import theme from "../components/page/theme"
import { ThemeProvider } from "theme-ui"

import UpcomingSpeaking from "../components/upcomingSpeaking"
import PreviousSpeaking from "../components/previousSpeaking"
import monicaSpeaking from "../images/monica-reactconf2.jpeg"

import {
  upcomingEngagements,
  previousEngagements,
} from "../utils/speakingEngagements"

const SpeakingPage = () => {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 600px)" })
  const desktopGridStyles = {
    display: "grid",
    gridGap: 4,
    gridAutoColumns: "200px",
    gridTemplateColumns: "repeat(2, 1fr)",
  }

  const mobileGridStyles = {
    display: "grid",
    gridGap: 4,
    gridAutoColumns: "100px",
    gridTemplateColumns: "repeat(1, 1fr)",
  }
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Speaking" />
        <h1>Speaking Engagements</h1>

        {isSmallScreen && (
          <div p={3}>
            <img src={monicaSpeaking} alt="Monica speaking at React Conf" />
          </div>
        )}
        <Flex>
          <Box p={3} width={isSmallScreen ? 1 : 1 / 2}>
            <p>
              I'm a software engineer who enjoys sharing my expertise with other
              developers and providing resources to make contributing to{" "}
              <a href="https://stars.github.com/profiles/m0nica/">
                open-source more approachable
              </a>
              . I have spoken about various{" "}
              <a href="https://jamstack.org/">Jamstack</a> technologies such as
              React, MDX, GraphQL, Git/GitHub in various formats including
              in-depth workshops, conference talks, podcasts and screencasts.
            </p>{" "}
            <p>
              In 2020, I was selected to be an inaugural{" "}
              <a href="https://stars.github.com/profiles/m0nica/">
                GitHub Star 🌟
              </a>{" "}
              based on my involvement in the tech community. My interest in the
              React ecosystem in particular led me to launch{" "}
              <a href="https://www.reactladies.com/">React Ladies</a>, a
              community for women and non-binary ReactJS developers.
            </p>
            <p>
              Interesting in having me speak at an upcoming event or workshop?{" "}
              <Link to={CONTACT}>Send me a message</Link>.
            </p>
          </Box>

          {!isSmallScreen && (
            <Box p={3} width={1 / 2}>
              <img src={monicaSpeaking} alt="Monica speaking at React Conf" />
            </Box>
          )}
        </Flex>

        <UpcomingSpeaking
          upcomingEngagements={upcomingEngagements}
          styles={isSmallScreen ? mobileGridStyles : desktopGridStyles}
        />

        <PreviousSpeaking
          previousEngagements={previousEngagements}
          styles={isSmallScreen ? mobileGridStyles : desktopGridStyles}
        />
        <br />
        <p>
          Key:{" "}
          <span role="img" aria-label="bolt-emoji">
            ⚡
          </span>{" "}
          = lightning talk
        </p>
        <a href="https://noti.st/monica" className="linkTo">
          View Presentations on Noti.st
        </a>
      </Layout>
    </ThemeProvider>
  )
}

export default SpeakingPage
