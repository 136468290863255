import React from "react"
import { Box } from "theme-ui"
import { Link } from "gatsby"
import { SPEAKING } from "../utils/routes"
import TicketIcon from "./icons/ticket.svg"

const UpcomingSpeaking = ({ upcomingEngagements, styles, isHomepage }) => (
  <>
    <h2>
      Upcoming Engagements{" "}
      {isHomepage && (
        <Link to={SPEAKING} className="linkToAll">
          View All Talks
        </Link>
      )}
    </h2>
    <Box sx={styles}>
      {upcomingEngagements &&
        upcomingEngagements.map(engagement => {
          const { name, link, title, date, city } = engagement

          return (
            <p key={name}>
              {name} {""}
              {link && (
                <a
                  href={link}
                  aria-label={`ticketLinkFor-${name}`}
                  className="speakingIcon"
                >
                  <img
                    src={TicketIcon}
                    alt="Ticket icon"
                    className="speakingIcon"
                  />
                </a>
              )}{" "}
              {title && (
                <>
                  {" "}
                  <br />
                  <strong> {title}</strong>
                </>
              )}
              <br /> {date}, {city}
            </p>
          )
        })}
    </Box>
  </>
)

export default UpcomingSpeaking
