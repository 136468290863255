import React from "react"
import { graphql, Link } from "gatsby"
import SEO from "../components/page/seo"
import Layout from "../components/page/layout"
import theme from "../components/page/theme"
import { ThemeProvider } from "theme-ui"
import terminalScreenshot from "../images/yonce-bash-it-terminal-screenshot.png"
import vsCodeScreenshot from "../images/vs-code-screenshot.png"
import WebMentions from "../components/WebMentions"
import { ARCHIVE } from "../utils/routes"
const Collection = ({ name, items, img }) => {
  return (
    <React.Fragment>
      {" "}
      <h2 className="collection">{name} &raquo;</h2>
      <div className="usesCollection">
        {img && <img src={img} alt={`${name}`} />}
        <ul>
          {items.map(({ item, url, description }) => (
            <li key={item}>
              {url ? <a href={url}>{item}</a> : item}
              {description && `, ${description}`}
            </li>
          ))}
        </ul>
      </div>
    </React.Fragment>
  )
}

function UsesPage({ location, data }) {
  const { allWebMentionEntry } = data
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Uses" />
        <h1>
          Uses{" "}
          <span role="img" aria-label="black-woman-technologist-emoji">
            👩🏾‍💻
          </span>
        </h1>

        <p>
          Here's a glimpse at some of the software and hardware I currently use
          day to day for web development and other festivities. If you'd like to
          know what technology I used on previous iterations of this site check
          out this site's <Link to={ARCHIVE}>archive</Link>.
        </p>
        <Collection
          name="Code Editor"
          img={vsCodeScreenshot}
          items={[
            {
              item: "Visual Studio Code",
              url: "https://code.visualstudio.com/",
              description: "a text editor",
            },
            {
              item: "Git Lens",
              url: "https://gitlens.amod.io/",
              description:
                "Quickly glimpse into whom, why, and when a line or code block was changed",
            },
            {
              item: "Material Icon Theme",
              url:
                "https://marketplace.visualstudio.com/items?itemName=PKief.material-icon-theme",
              description:
                "displays Material Design icons for different file types",
            },
            {
              item: "Horizon",
              url: "https://horizontheme.netlify.app/",
              description:
                "a beautifully warm dual theme for Visual Studio Code (pictured)",
            },
            {
              item: "Night Owl",
              url:
                "https://marketplace.visualstudio.com/items?itemName=sdras.night-owl",
              description:
                "sometimes I use Night Owl which is a beautiful theme fine-tuned to be accessible and provide meaningful contrast.",
            },
            {
              item: "Dank Mono",
              url: "https://dank.sh/",
              description: "a rather special coding font",
            },
            {
              item: "Power Mode",
              url: "/blog/how-to-make-your-vs-code-sparkle/",
              description:
                "adds animations that appear while typing in VSCode ",
            },
          ]}
        />
        <Collection
          name="Terminal"
          img={terminalScreenshot}
          items={[
            {
              item: "Terminal",
              description: "built-in MacOS",
            },
            {
              item: "Integrated Terminal",
              description: "built-in VS Code",
            },
            {
              item: "Yoncé",
              url: "https://yoncetheme.com/",
              description: "a Queen Bey inspired BashIt theme",
            },
          ]}
        />
        <Collection
          name="Hardware"
          items={[
            { item: "MacBook Pro", url: "https://amzn.to/2ZhYRtf" },
            {
              item: "Apple Wireless Magic Keyboard 2",
              url: "https://amzn.to/330XyzS",
            },
            {
              item: "Magic Trackpad 2",
              url:
                "https://www.amazon.com/gp/product/B016QO5YWC/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B016QO5YWC&linkCode=as2&tag=monica0dc-20&linkId=4acab4ff9afe8c6c99d0a542c6177613",
            },
            {
              item: "Apple Airpods",
              url:
                "https://www.amazon.com/gp/product/B07PXGQC1Q/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07PXGQC1Q&linkCode=as2&tag=monica0dc-20&linkId=00331f16b0136e86fa6baa64b252dace",
            },
          ]}
        />
        <Collection
          name="This site was built with </>"
          items={[
            {
              item: "Webmentions",
              url: "https://indieweb.org/Webmention",
              description: "comments on this site are powered by webmentions",
            },
            { item: "Markdown" },
            { item: "GitHub" },
            { item: "Gatsby", url: "https://www.gatsbyjs.org/" },
            {
              item: "Shapes Icons",
              url: "https://shape.so/app/icons/favorites",
            },
            { item: "Simple Icons", url: "https://simpleicons.org" },
            { item: "Netlify", url: "https://www.netlify.com/" },
          ]}
        />
        <Collection
          name="Miscellaneous"
          items={[
            {
              item: "YNAB",
              url:
                "https://ynab.com/referral/?ref=r4CK2UYdJtBiL6r5&utm_source=customer_referral",
              description:
                "a life-changing budgeting app (get a free month when signing up via my link)",
            },
            {
              item: "Bear",
              url: "https://bear.app/",
              description: "a simple note taking app",
            },
            { item: "Spotify", description: "play Whitney Houston on repeat" },
            {
              item: "Scanner Pro",
              url: "https://readdle.com/scannerpro",
              description: "scan documents on the go",
            },
            {
              item: "Calendly",
              url: "https://calendly.com/",
              description: "avoid having another scheduling conflict again",
            },
          ]}
        />
        <p>
          Some of the links on this page are affiliate links. This means that I
          may receive a small commission, at no cost to you, if you purchase
          something through the links provided.
        </p>
        <WebMentions {...allWebMentionEntry} />
      </Layout>
    </ThemeProvider>
  )
}

export const pageQuery = graphql`
  query UsesPage {
    site {
      siteMetadata {
        bio
        author
        twitterHandle
        siteUrl
      }
    }
    allWebMentionEntry(
      filter: { wmTarget: { eq: "https://www.aboutmonica.com/uses/" } }
    ) {
      edges {
        node {
          wmTarget
          wmSource
          wmProperty
          wmId
          type
          url
          likeOf
          author {
            url
            type
            photo
            name
          }
          published(formatString: "MMMM DD, YYYY")
          content {
            text
          }
        }
      }
    }
  }
`

export default UsesPage
