/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import defaultMetaImage from "../../images/seo-banner.png"

function SEO({ description, lang, meta, title, slug, tags = [] }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  const isBlogPost = tags.length > 1;


  const generatedArticleImg = isBlogPost && `${process.env.NODE_ENV === "development" ? "http://localhost:8888" : site.siteMetadata.siteUrl
    }/generated-image?title=${encodeURIComponent(title)}&tags=${encodeURIComponent(tags)}`;

  const url = `${site.siteMetadata.siteUrl}${slug} `
  const canonicalUrl = slug ? `${url} ` : `${site.siteMetadata.siteUrl} `
  const socialCard = generatedArticleImg ? generatedArticleImg : `${site.siteMetadata.siteUrl}${defaultMetaImage}`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      link={[{ rel: "canonical", href: canonicalUrl }]}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },

        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          property: "og:image",
          content: socialCard,
        },
        {
          name: "twitter:image",
          content: socialCard,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
