import React from "react"
import { Link } from "gatsby"
import * as styles from "./SeoImageTags.module.scss"
import { Button } from "theme-ui"
const kebabCase = require(`lodash.kebabcase`)

function Tags({ tags }) {
    if (!tags) return []
    return tags.map(tag => (
        <Button className={styles.squareButton} key={tag} >
            {" "}
            < Link to={`/tags/${kebabCase(tag)}/`}>
                <span>
                    {tag}
                </span>
            </Link >
        </Button >
    ))
}

export default Tags
