import React from "react"
import quote from "../../images/creativity-quote.svg"

const Logo = () => (
  <>
    <img
      src={quote}
      alt="You can't use up creativity. The more you use, the more you have."
    />
    <p> ― Dr. Maya Angelou</p>
  </>
)

export default Logo
