import React from "react"
import "./signup.css"
const FORM_ID = "1415393"

class Signup extends React.Component {
  render() {
    let { post } = this.props
    const form = {
      id: FORM_ID,
      title: "Don't be a stranger! 👋🏾",
      subTitle: `Thanks for reading "${post}". Join my mailing list to be the first to receive my newest web development content, my thoughts on the web and learn about exclusive opportunities.`,
      buttonText: "Subscribe",
    }

    return (
      <form
        action={`https://app.convertkit.com/forms/${form.id}/subscriptions`}
        className="seva-form formkit-form"
        method="post"
        min-width="400 500 600 700 800"
        style={{
          boxShadow: "var(--form-shadow)",
          backgroundColor: "var(--bg)",
          borderRadius: "6px",
        }}
      >
        <div data-style="full">
          <div data-element="column" className="formkit-column">
            <h1
              className="formkit-header"
              data-element="header"
              style={{
                color: "var(--textTitle)",
                fontSize: "20px",
                fontWeight: 700,
              }}
            >
              {form.title}
            </h1>
            <div
              data-element="subheader"
              className="formkit-subheader"
              style={{ color: "var(--textNormal)" }}
            >
              <p>{form.subTitle}</p>
            </div>
            <ul
              className="formkit-alert formkit-alert-error"
              data-element="errors"
              data-group="alert"
            />

            <div data-element="fields" className="seva-fields formkit-fields">
              <div className="formkit-field">
                <input
                  className="formkit-input"
                  aria-label="Your first name"
                  name="fields[first_name]"
                  placeholder="Your first name"
                  type="text"
                  style={{
                    borderColor: "rgb(227, 227, 227)",
                    borderRadius: "4px",
                    color: "rgb(0, 0, 0)",
                    fontWeight: 400,
                  }}
                  required
                />{" "}
                &nbsp;
                <input
                  className="formkit-input"
                  name="email_address"
                  aria-label="Your email"
                  placeholder="Your email"
                  required
                  type="email"
                  style={{
                    borderColor: "rgb(227, 227, 227)",
                    borderRadius: "4px",
                    color: "rgb(0, 0, 0)",
                    fontWeight: 400,
                  }}
                />
              </div>

              <button
                data-element="submit"
                className="formkit-submit formkit-submit"
                style={{
                  backgroundColor: "#e0e1ff",
                  borderRadius: "24px",
                  color: "#2a2135",
                  fontWeight: 700,
                }}
              >
                <div className="formkit-spinner" />
                <span>{form.buttonText}</span>
              </button>
            </div>
            <div
              data-element="guarantee"
              className="formkit-guarantee"
              style={{
                color: "var(--textNormal)",
                fontSize: "13px",
                fontWeight: 400,
              }}
            >
              <p>
                I won’t send you spam. Unsubscribe at <em>any</em> time.
              </p>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

export default Signup
