import React from "react"

import Layout from "../components/page/layout"
import SEO from "../components/page/seo"
import Cards from "../components/cardComponent"

import aboutMonicaOld from "../images/about-monica-old-cropped-16-9-ratio.png"
import MonicaV2 from "../images/v2-about-monica.png"

import CurrentWebsite from "../images/website-preview.png"

const projects = [
  {
    img: MonicaV2,
    text:
      "A fully-responsive, personal portfolio built with HTML/CSS/JavaScript that was a previous iteration of the website you are currently viewing.",
    website:
      "https://web.archive.org/web/20190401110809/http://www.aboutmonica.com/",
    technologies: "HTML/CSS, Zurb Foundation, JavaScript and jQuery.",
  },

  {
    img: aboutMonicaOld,
    text: "A fully-responsive, portfolio created with a contact form.",
    website:
      "https://web.archive.org/web/20170915195240/http://aboutmonica.com/",
    github: "https://github.com/M0nica/AboutMonica",
    technologies: "HTML/CSS, JavaScript and PHP.",
  },
]

const currentSite = [
  {
    img: CurrentWebsite,
    text:
      "I consider this current iteration of my site my digital playground. I've learned a lot about GraphQL, Gatsby and my digital creativity through maintaining this site.",
    website: "https://aboutmonica.com",
    technologies:
      "GatsbyJS, a React Framework, Webmentions, GraphQL, Markdown and MDX",
  },
]

const ArchivePage = () => {
  return (
    <Layout>
      <SEO title="Archive" />
      <h1>Archive</h1>
      <h2>Previous Versions</h2>
      <Cards items={projects} />
      <br />
      <h2>Current Site</h2>
      <Cards items={currentSite} />
    </Layout>
  )
}

export default ArchivePage
