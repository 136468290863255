import React from "react"
import LinkedInIcon from "../icons/linkedin.svg"
import TwitterIcon from "../icons/twitter.svg"
import GitHubIcon from "../icons/github.svg"
import TwitchIcon from "../icons/twitch.svg"
import CodepenIcon from "../icons/codepen.svg"
import InstagramIcon from "../icons/instagram.svg"
import MastodonIcon from "../icons/mastodon.svg"

const socialMediaInfo = [
  {
    network: "github",
    icon: GitHubIcon,
    link: "https://github.com/m0nica"
  }, {
    network: "Codepen",
    icon: CodepenIcon,
    link: "https://codepen.io/m0nica"
  },
  {
    network: "Mastodon",
    icon: MastodonIcon,
    link: "https://hachyderm.io/@indigitalcolor"
  },
  {
    network: "Instagram",
    icon: InstagramIcon,
    link: "https://www.instagram.com/indigitalcolor/",
  },

  {
    network: "linkedin",
    icon: LinkedInIcon,
    link: "https://www.linkedin.com/in/monicampowell/",
  },
  {
    network: "Twitch",
    icon: TwitchIcon,
    link: "https://www.twitch.tv/blacktechdiva",
  }, {
    network: "twitter",
    icon: TwitterIcon,
    link: "https://twitter.com/indigitalcolor",
  },
]

const SocialMedia = (placement = { placement: "default" }) => (
  <ul className="socialIcons">
    {socialMediaInfo &&
      socialMediaInfo.map(socialNetwork => {
        const { network, icon, link } = socialNetwork
        return (
          <li key={`${placement.placement}-${network}`}>
            <a href={link} aria-label={`externalLinkTo-${network}`} rel="me">
              <img src={icon} alt={`${network}-icon`} className="socialIcon" />
            </a>
          </li>
        )
      })}
  </ul>
)

export default SocialMedia
