import React from "react"

import Layout from "../components/page/layout"
import SEO from "../components/page/seo"

import theme from "../components/page/theme"

import { ThemeProvider } from "theme-ui"

import snailMail from "../images/snail-mail.gif"
const ThanksPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <h1>Talk Soon</h1>
        <SEO title="Thanks" />

        <p>Thank you for contacting me. I will be in touch shortly!</p>
        <img src={snailMail} alt="snail getting mail" />
        {""}
      </Layout>
    </ThemeProvider>
  )
}

export default ThanksPage
