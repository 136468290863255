import React from "react"

import { Link } from "gatsby"
import { WRITING } from "../utils/routes"
import ArticleIcon from "./ArticleIcon"

function ArticleList({ articles, featured, latest, egghead }) {
  return (
    <div>
      <h2>
        {featured && "Featured Writing"}
        {latest && "Latest Writing"}
        {egghead && "Latest Lessons"}
        {egghead ? (
          <a
            href="https://egghead.io/instructors/monica-powell?af=9b5hrz"
            className="linkToAll"
          >
            View All Lessons
          </a>
        ) : (
          (featured || latest) && (
            <Link to={WRITING} className="linkToAll">
              View All Content
            </Link>
          )
        )}
      </h2>

      {articles &&
        articles.map(
          ({ slug, tags, title, external_link, publication }, index) => (
            <div className="article-blurb" key={`${index}-${slug}`}>
              <span className="article-title-icon-wrap">
                <ArticleIcon tags={tags} />
                <p>
                  {external_link ? (
                    <a
                      href={external_link}
                      className="article-link"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {title} &bull;{" "}
                      <span className="external-publication-name">
                        {publication}
                      </span>{" "}
                      <span className="screen-reader-only">
                        (opens in a new tab)
                      </span>
                    </a>
                  ) : egghead ? (
                    <a href={`${slug}?af=9b5hrz`} className="article-link">
                      {title}
                    </a>
                  ) : (
                    <Link to={slug} className="article-link">
                      {title}
                    </Link>
                  )}
                </p>
              </span>
            </div>
          )
        )}

      <br />
    </div>
  )
}

export default ArticleList
