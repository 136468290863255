import React from "react"

import theme from "./page/theme"

import { Flex, Card, Text, Box, ThemeProvider } from "theme-ui"

import { Link } from "gatsby"

const CardComponent = ({ text, img, read, website, github, technologies }) => (
  <Flex>
    <Card
      width={[400, 500, 600]}
      mx="auto"
      variant="card"
      className="card"
      key={text}
    >
      {img && <img src={img} alt={text} />}
      <Text padding="1em">
        {text}
        {technologies && (
          <>
            <br />
            <br />
            <p>
              <b>Technologies:</b> {technologies}
            </p>
          </>
        )}
        {read && (
          <>
            <br />
            <Link to={read} className="linkTo">
              Read
            </Link>
          </>
        )}
      </Text>

      <Box
        sx={{
          display: "grid",
          gridGap: 1,
          gridTemplateColumns: "repeat(auto-fit, minmax(128px, 1fr))",
        }}
      >
        {!read && (
          <p>
            &nbsp; &nbsp;
            <>
              {github && (
                <>
                  <a href={github}> GitHub</a> &nbsp;{" "}
                </>
              )}
              {website && <a href={website}> Website</a>}
            </>
          </p>
        )}
      </Box>
    </Card>
  </Flex>
)

export const Cards = props => {
  const { items } = props

  return (
    <ThemeProvider theme={theme}>
      <Box className="cardContainer">
        {" "}
        {items &&
          items.map((item, index) => (
            <>
              <CardComponent
                className="card"
                text={item.text}
                key={item.text}
                img={item.img}
                read={item.read}
                website={item.website}
                github={item.github}
                technologies={item.technologies}
              />
            </>
          ))}
      </Box>
    </ThemeProvider>
  )
}

Card.propTypes = {}

Card.defaultProps = {}

export default Cards
