import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ArticleList from "./ArticleList"

function LatestWriting() {
  const { allMdx } = useStaticQuery(
    graphql`
      query {
        allMdx(
          filter: { fileAbsolutePath: { regex: "g//blog|external//" } }
          sort: { fields: [frontmatter___date], order: DESC }
          limit: 4
        ) {
          edges {
            node {
              frontmatter {
                title
                tags
                external_link
                publication
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `
  )

  const articles = []

  const { edges } = allMdx
  edges.forEach(({ node }) => {
    articles.push({
      title: node.frontmatter.title,
      slug: node.fields.slug,
      tags: node.frontmatter.tags,
      external_link: node.frontmatter.external_link,
      publication: node.frontmatter.publication,
    })
  })

  return <ArticleList articles={articles} latest />
}

export default LatestWriting
