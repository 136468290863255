/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

import { Link } from "gatsby"

import HeartIcon from "../icons/heart.svg"
import CodeIcon from "../icons/code.svg"

import SocialMedia from "./socialMedia"

import "./layout.scss"

import Quote from "./quote"

const Footer = () => {
  return (
    <footer>
      <Quote />
      <center>
        <SocialMedia placement="footer" />
      </center>

      <br />
      <p
        style={{
          fontSize: `.9em`,
        }}
      >
        Interested in collaborating?{" "}
        <Link to="/contact/">Send me a message</Link> &bull; Want to support my
        work?{" "}
        <a className="linkTo" href="https://github.com/sponsors/M0nica">
          Become a GitHub Sponsor
          <span role="img" aria-label="tea emoji"></span>
        </a>
      </p>

      <p
        style={{
          fontSize: `.9em`,
        }}
      >
        <br />
        Made with <img src={HeartIcon} alt="Heart" className="footerIcon" />,
        &nbsp;
        <img src={CodeIcon} alt="Code Brackets" className="footerIcon" />, &amp;
        &nbsp;
        <a href="/uses">various tech</a> in New York City
        <br /> © Monica Powell {new Date().getFullYear()}
      </p>
    </footer>
  )
}

export default Footer
