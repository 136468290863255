import React from "react"

import Layout from "../components/page/layout"

import SEO from "../components/page/seo"

import theme from "../components/page/theme"
import { ThemeProvider } from "theme-ui"

import { useMediaQuery } from "react-responsive"

import Header from "../components/header"
import Engagements from "../components/engagements"
import FeaturedWriting from "../components/featuredWriting"
import LatestWriting from "../components/latestWriting"
// import LatestLessons from "../components/latestLessons"

function IndexPage() {
  const isSmallScreen = useMediaQuery({
    query: "(min-width: 840px)",
  })

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Home" />

        <Header isSmallScreen={isSmallScreen} />

        <hr className="indexDivider" />

        <FeaturedWriting />

        <br />

        <hr className="indexDivider" />

        <Engagements />

        <hr className="indexDivider" />

        <LatestWriting />
        <br />
        {/*<LatestLessons />*/}
      </Layout>
    </ThemeProvider>
  )
}

export default IndexPage
