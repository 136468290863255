import PropTypes from 'prop-types';
import React from 'react';
import Image from './image';
import { Link } from 'gatsby';
import SocialMedia from './page/socialMedia';

import BikeIcon from './icons/bike.svg';
import { Box } from 'theme-ui';

const Header = ({ isSmallScreen }) => (
  <>
    <Box className='headerContainer h-card'>
      <a
        rel='me'
        href='https://hachyderm.io/@indigitalcolor'
        className='u-url u-uid hidden'
      >
        ""
      </a>
      <a
        href='http://www.aboutmonica.com/'
        rel='me'
        className='u-url u-uid hidden'
      >
        ""
      </a>
      <a
        href='https://twitter.com/indigitalcolor'
        rel='me'
        className='u-url u-uid hidden'
      >
        ""
      </a>
      <div className='headerText'>
        <h1 className='siteHeadline' p={8}>
          <span className='underline p-name'>Monica Powell</span>{' '}
        </h1>
        <h2 className='headline'>
          software engineer, content creator & community organizer
        </h2>
        <SocialMedia placement='headline' />
      </div>

      <div className='headerImage'>
        <Image />
      </div>
    </Box>
    <p className='intro p-summary p-note'>
      {' '}
      Hi, I'm Monica! Welcome to my digital playground
      <img src={BikeIcon} alt='bicycle icon' />. I'm passionate about making
      contributing to{' '}
      <a href='https://stars.github.com/profiles/m0nica/'>
        open-source more approachable
      </a>
      , creating technology to elevate people, and building community. You can
      find me building educational technology as a Senior Software Engineer at{' '}
      <a href='https://www.newsela.com'>Newsela</a>, teaching web development on
      this site as well as on{' '}
      <a
        href='https://egghead.io/instructors/monica-powell?af=9b5hrz'
        target='_blank'
        rel='noopener noreferrer'
      >
        Egghead
      </a>
      , contributing to open-source projects and tending to{' '}
      <a
        href='https://www.meetup.com/react-ladies/'
        target='_blank'
        rel='noopener noreferrer'
      >
        React Robins
      </a>
      , a community I founded for women and non-binary React developers.
    </p>
    <p className='intro'>
      {' '}
      Interested in collaborating? <Link to='/contact/'>
        Send me a message
      </Link>{' '}
    </p>
  </>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
