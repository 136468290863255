import React from "react"
import qs from "qs"
import Logo from "../components/page/logo"
import avatar from "../images/monica-cropped-bg.png"
import * as styles from "./metaimage.module.scss"
import Tags from "../components/SeoImageTags"
import ArticleIcon from "../components/ArticleIcon"
import TwitterIcon from "../components/icons/twitter.svg"
const MetaImg = ({ location }) => {

    const title = qs.parse(location.search.replace("?", "")).title || ""

    const rawTags = (qs.parse(location.search.replace("?", "")).tags)

    const tags = rawTags?.length ? rawTags.split(",") : []
    const tagsToDisplay = tags.slice(0, 3);

    return (

        <div className={styles.container} >
            <div className={styles.main}>
                <h1 className={styles.title}>  <span className="article-title">{title}</span>  </h1>
                <div className={styles.meta}><span className={styles.icon}><ArticleIcon tags={tags} /></span>  <Tags tags={tagsToDisplay} /></div> </div>

            <div className={styles.footer}>

                <img
                    src={avatar}
                    className={styles.avatar}
                    alt="animated headshot of Monica"
                /> <Logo variant="logo" padding="10em" />
                <span className={styles.sitename}>&bull; aboutmonica.com &nbsp;&nbsp; </span><img src={TwitterIcon} className={styles.socialIcon} /> <span className={styles.sitename}>@indigitalcolor</span> </div>
        </div >
    )
}

export default MetaImg;
