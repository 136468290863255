import React from "react"
import { Link } from "gatsby"
import { SimplecastEpisode } from "mdx-embed"
import { CONTACT } from "../utils/routes"
const featuredEngagements = [
  {
    name: "Learn with Jason",
    title: "Webmention + Next.js",
    type: "livestream",
    description:
      "Did you know that Webmentions let you pull tweets, other blogs, and other activity from around the web into your site? In this episode of Learn with Jason, I walk through how to add it to a Next.js site!",
    city: "Cyberspace",
    link: "https://www.learnwithjason.dev/webmention-next-js",
  },
  {
    name: "Egghead Podcast",
    title: "Personal Growth From Open-Source And Meetups",
    type: "podcast",
    description:
      "A conversation with Joel Hooks of Egghead.io about my journey to becoming a web developer and becoming a tech community organizer.",
    city: "Cyberspace",
    video:
      "https://egghead.simplecast.com/episodes/personal-growth-from-open-source-and-meetups-with-monica-powell",
    link:
      "https://egghead.io/podcasts/personal-growth-from-open-source-and-meetups-with-monica-powell",
  },
]
const FeaturedEngagements = () => (
  <>
    <h2>
      Appearances{" "}
      <Link to={CONTACT} className="linkToAll">
        Invite Me To Speak
      </Link>
    </h2>{" "}
    <p>
      {" "}
      <em>
        Some of my favorite guest appearances on podcasts and livestreams{" "}
      </em>
    </p>
    <p>
      <a href="https://hanselminutes.com/753/leaning-into-learning-in-public-with-monica-powell">
        Hanselminutes Podcast with Scott Hanselman
      </a>
      <SimplecastEpisode episodeId="70833e74-22f2-4c13-8dbd-8e353d51ac64" />
    </p>
    <br />
    <div className="cardContainer">
      {featuredEngagements &&
        featuredEngagements.map(engagement => {
          const { name, link, title, description } = engagement

          return (
            <p key={name}>
              {link && (
                <a
                  href={link}
                  aria-label={`ticketLinkFor-${name}`}
                  className="speakingIcon"
                >
                  {name}
                  {title && (
                    <>
                      {" "}
                      &bull;
                      <strong> {title}</strong>
                    </>
                  )}
                </a>
              )}
              <br /> {description}
            </p>
          )
        })}
    </div>
  </>
)

export default FeaturedEngagements
