import React from "react"
import "./TableOfContents.scss"
function TableOfContents({ headings }) {
  return (
    <div className="table-of-contents">
      {" "}
      <h2>Table of Contents</h2>
      <ul>
        {headings.items &&
          headings.items.map(item => (
            <li key={item.url}>
              <a href={item.url}>{item.title}</a>{" "}
            </li>
          ))}
      </ul>
    </div>
  )
}

export default TableOfContents
