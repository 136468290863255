export const upcomingEngagements = [
  {
    name: "Render ATL",
    title: "",
    type: "conference",
    date: "June 1st-4th, 2022",
    city: "Atlanta, Georgia",
    link: "https://www.renderatl.com/",
  },
]

export const previousEngagements = [
  { 
  name: "Frontend Horse's Holiday Snowtacular",
  title: "Animating a Snowglobe with CSS",
  date: "December 2021",
  city: "Cyberspace",
  link: "https://www.frontend.horse/",
  },  
  {
    name: "React Robins",
    title: "Getting Started with Open Source",
    date: "October 2021",
    city: "Cyberspace",
    link: "https://www.reactrobins.com/",
  },
  {
    name: "GitHub Universe",
    title: "Using Code as Documentation to Save Time and Share Context",
    type: "conference",
    date: "October 2021",
    city: "Cyberspace",
    link: "https://githubuniverse.com/",
  },
  {
    name: "Jamstack Conf",
    title: "Workshop: Building Modern Websites with GatsbyJS",
    type: "conference",
    date: "October 2021",
    city: "Cyberspace",
    link: "https://jamstackconf.com/",
  },
  {
    name: "Barnard College",
    title: "Career Insights: Computing/Data Science Panel",
    type: "panel",
    date: "September 2021",
    city: "Cyberspace",
    link: "https://barnard.edu/",
  },
  {
    name: "FSJam Podcast",
    title: "Open Source Education",
    type: "podcast",
    date: "April 2021",
    link:
      "https://fsjam.org/episodes/episode-27-open-source-education-with-monica-powell",
    city: "Cyberspace",
  },
  {
    name: "Apollo Days",
    title: "Dynamic Authoring with MDX",
    type: "talk",
    date: "November, 10th, 2020",
    city: "Cyberspace",
    link: "https://go.apollo.dev/apollo-day",
  },
  {
    name: "Ladybug Podcast",
    title: "Git & GitHub",
    type: "talk",
    date: "November, 2020",
    city: "Cyberspace",
    link: "https://www.ladybug.dev/episodes/git-and-github",
  },

  {
    name: "Write/Speak/Code (NYC and Austin)",
    title: "Getting Started With Open Source",
    type: "workshop",
    date: "October, 20th, 2020",
    city: "Cyberspace",
    link: "https://www.meetup.com/Write-Speak-Code-NYC/events/272632827/",
  },
  {
    name: "Jamstack Conf",
    title: "Workshop: Getting Started with GatsbyJS",
    type: "conference",
    date: "October 2020",
    city: "Cyberspace",
    link: "https://jamstackconf.com/",
  },
  {
    name: "Learn with Jason",
    title: "Webmention + Next.js",
    type: "livestream",
    date: "October 2020",
    city: "Cyberspace",
    link: "https://www.learnwithjason.dev/webmention-next-js",
  },
  {
    name:
      "Management of Software Development Course at Yale School of Management",
    title: "Using GitHub for Collaboration Q&A",
    type: "livestream",
    date: "September 2020",
    city: "Cyberspace",
  },
  {
    name: "Hanselminutes Podcast",
    title: "Leaning Into Learning In Public",
    link:
      "https://hanselminutes.com/753/leaning-into-learning-in-public-with-monica-powell",
    type: "podcast",
    date: "September 2020",
    city: "Cyberspace",
  },

  {
    name: "MDXConf",
    title: "Migrating to MDX",
    video: "https://egghead.io/lessons/mdx-migrating-to-mdx",
    type: "conference",
    date: "August 2020",
    city: "Cyberspace",
    link: "https://mdxjs.com/conf/",
  },
  {
    name: "React Rally",
    title: "Keeping Server-Side Rendering Cool With React Hydration",
    type: "conference",
    date: "August 2020",
    city: "Cyberspace",
    link: "https://www.reactrally.com/",
  },
  {
    name: "GraphQL Summit",
    type: "conference",
    title: "Learning GraphQL Panel",
    date: "July 2020",
    city: "Cyberspace",
    link: "https://summit.graphql.com/",
  },
  {
    name: "IncludeJS",
    title: "Discussion about JavaScript and automation walkthrough + demo",
    type: "livestream",
    date: "July 2020",
    city: "Cyberspace",
    link: "https://includejs.dev/",
  },
  {
    name: "Egghead Podcast",
    title: "Personal Growth From Open-Source And Meetups",
    type: "podcast",
    date: "December 2019",
    city: "Cyberspace",
    video:
      "https://egghead.simplecast.com/episodes/personal-growth-from-open-source-and-meetups-with-monica-powell",
    link:
      "https://egghead.io/podcasts/personal-growth-from-open-source-and-meetups-with-monica-powell",
  },
  {
    name: "Barnard College",
    type: "panel",
    date: "October 2019",
    city: "New York, NY",
    title: "Career Insights Panel: Creative & Front End Tech",
  },
  {
    name: "React Conf",
    type: "conference",
    date: "October 2019",
    city: "Henderson, Nevada",
    link: "https://conf.reactjs.org/",
    title: "Git is Hard but Time Traveling in Git Isn't (⚡)",
    video: "https://noti.st/monica/videos/FfRNGI",
  },
  {
    name: "useReact NYC",
    title: "Automate Your React Workflow",
    date: "June 2019",
    city: "New York, NY",
    link: "https://www.meetup.com/useReactNYC/events/261642886/",
  },
  {
    name: "ReactJS Girls Conference",
    title: "Automate Your React Workflow",
    date: "May 2019",
    type: "conference",
    city: "London, UK",
    link: "https://reactjsgirls.com/",
    video: "https://noti.st/monica/videos/AjCX04",
  },
  {
    name: "Barnard College",
    title: "Women in Tech Panel",
    date: "April 2019",
    city: "New York, NY",
  },
  {
    name: "Women Who Code NYC",
    title: "Git is Hard but Time Traveling in Git Isn't (⚡)",
    date: "March 2019",
    city: "New York, NY",
    link: "https://www.meetup.com/WomenWhoCodeNYC/events/258691045/",
  },
  {
    name: "Flatiron School",
    title: "Women Trailblazers in Tech",
    date: "March 2019",
    city: "Brooklyn, NY",
  },
  {
    name: "Columbia University",
    title: "National Society of Black Engineers (NSBE) Alumni Panel",
    date: "February 2019",
    city: "New York, NY",
  },
  {
    name: "Girl Develop It",
    title: "A Day in the Life Panel: Career Changers Edition",
    date: "November 2018",
    city: "New York, NY",
    link: "https://www.meetup.com/girldevelopit/events/255968577/",
  },
  {
    name: "Codeland Conf",
    title: "Technical Blogging with Jekyll Workshop",
    date: "May 2018",
    city: "New York, NY",
    link: "https://noti.st/monica/raD7Ws/technical-blogging-with-jekyll",
  },
]
